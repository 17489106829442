@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=B612+Mono:wght@400;700&display=swap');
@import './css/custom-bootstrap.scss';

@font-face {
  font-family: 'II Vorkurs Bold';
  src: local('II Vorkurs Bold'), url("./assets/fonts/II Vorkurs Bold.ttf") format('truetype');
}

$red: #EF4130;
$yellow: #FFD400;

body {
  font-family: 'Poppins', sans-serif;
  line-height: 25px;
}
ul {
  font-family: 'Poppins', sans-serif;
  font-size:18px;
}
.quoteCallout{
  font-style: italic;
  font-size:24px;
}
.whiteText{
  color: white;
}
.redText{
  color:$red;
}
.yellowText{
  color:$yellow;
}
.whiteAnchor{
  font-size:18px;
  color:white;
  text-decoration: none;
}
.whiteAnchor:hover {
  color: $red;
}

.lightTextBg{
    background-color: #f9f9f9ba;
    padding:10px;
    border-radius:25px;
}
.black{
  background-color: black;
}
.pink{
  background-color: #EF41300D;
}
.yellow{
  background-color: #FFD40045;
} 

/* Mobile */
.blackCallout {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color:black;
    padding: 20px 20px 20px 20px;
    border-radius: 32px 32px 32px 32px;
}

/* Tablet */
@media (min-width:600px) {
    .blackCallout{
        width: "90%";
        margin-top: 0px;
        margin-bottom: 0px;
        max-width:900px;
        background-color:black;
        padding: 35px 40px 40px 40px;
        border-radius: 32px 32px 32px 32px;
      }
}

/* Desktop */
@media (min-width:900px) {
    .blackCallout{
        max-width:1200px;
        margin-top: 0px;
        margin-bottom: 0px;
        background-color:black;
        padding: 70px 80px 80px 80px;
        border-radius: 32px 32px 32px 32px;
      }
}



.footerAnchor{
  text-align:"left" !important;
  color: "white" !important;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "II Vorkurs Bold", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
}

.footerFont{
  color: #FFFFFF99;
  font-family: "II Vorkurs Bold", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.3);
}
.main{
  padding-left:10%;
  padding-right:10%;
  padding-top:50px;
  padding-bottom: 50px;
}

/* ========= Fonts ========== */
h1 {
  font-family: "II Vorkurs Bold", sans-serif;
  color: #000000;
  font-size: 48px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: -3px;
  margin-top:10px;
  margin-bottom: 10px;
}

h3 {
  color: #000000;
  font-family: "II Vorkurs Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
}

h5 {
  color: $red;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

p {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0.2px;
  margin-top: 20px;
  margin-bottom:20px;
}

.bold {
  color:#000000;
  font-weight:500;
}

.btn-danger,
.btn-danger:hover {
  font-family: "II Vorkurs Bold", Sans-serif;
  color: white;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.3);
  background-color: $red;
  border-radius: 32px 0px 32px 32px;
  box-shadow: 0px 12px 32px 0px rgba(238.99999999999997, 64.99999999999997, 47.999999999999986, 0.4);
  padding: 20px 50px 20px 50px;
}
